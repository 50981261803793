import React from 'react'
import Panel from '../../layout/Panel/Panel'
import ProfileSetting from "../../components/dashboard/profile/profile"

const Profile = () => {
  return (
    <Panel>
      <ProfileSetting />
    </Panel>
  )
}

export default Profile