import React from "react";
import TermsAndConditionsSection from "../../components/website/terms_and_conditions/terms_and_conditions"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"

function TermsAndConditions() {
    return(
        <>
            <Header />
            <TermsAndConditionsSection/>
            <Footer />
        </>
    )
}

export default TermsAndConditions