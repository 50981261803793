export const isOpenHandle = (label, from, to, isOpen) => {
  const dayIndices = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  }

  const dayIndex = dayIndices[label]

  let currentTime = new Date()
  let currentDay = currentTime.getDay()
  let dayDiff = dayIndex - currentDay
  if (dayDiff < 0) {
    dayDiff += 7
  }

  let openingTime = new Date()
  openingTime.setHours(
    parseInt(from.split(":")[0]),
    parseInt(from.split(":")[1]),
    0,
    0,
  )
  openingTime.setDate(currentTime.getDate() + dayDiff)

  let closingTime = new Date()
  closingTime.setHours(
    parseInt(to.split(":")[0]),
    parseInt(to.split(":")[1]),
    0,
    0,
  )
  closingTime.setDate(currentTime.getDate() + dayDiff)

  if (
    currentTime.getTime() > openingTime.getTime() &&
    currentTime.getTime() < closingTime.getTime() &&
    isOpen
  ) {
    return true
  }

  return false
}
