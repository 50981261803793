import { createSlice } from "@reduxjs/toolkit"

const cartSlice = createSlice({
  name: "cartSlice",
  initialState: localStorage.getItem("mealsbusCart")
    ? JSON.parse(localStorage.getItem("mealsbusCart"))
    : { cart: [], rid: null, totalQuantity: 0, totalPrice: 0 },

  reducers: {
    addItem: (state, { payload }) => {
      if (state.cart.length === 0) {
        state.rid = payload.rid
      } else if (state.cart.length > 0 && state.rid !== payload.rid) {
        alert(
          "You can order from only one Restaurant at a time or Clear the cart",
        )
        return state
      }

      const isFound = state.cart.find((item) => item.id === payload.id)
      if (!isFound) {
        state.cart.push({ ...payload, quantity: 1 })
      } else {
        state.cart = state.cart.map((item) =>
          item.id === payload.id
            ? { ...item, quantity: item.quantity + 1 }
            : item,
        )
      }

      localStorage.setItem("mealsbusCart", JSON.stringify(state))
    },

    addItemWithQuantity: (state, { payload }) => {
      if (state.cart.length === 0) {
        state.rid = payload.item.rid
      } else if (state.cart.length > 0 && state.rid !== payload.item.rid) {
        alert("You can order from only one Restaurant at a time")
        return state
      }

      const isFound = state.cart.find((item) => item.id === payload.item.id)
      if (!isFound) {
        state.cart.push({ ...payload.item, quantity: payload.quantity })
      } else {
        state.cart = state.cart.map((item) =>
          item.id === payload.item.id
            ? { ...item, quantity: payload.quantity }
            : item,
        )
      }
      localStorage.setItem("mealsbusCart", JSON.stringify(state))
    },

    removeItem: (state, { payload }) => {
      state.cart = state.cart.filter((item) => item.id !== payload)
      if (state.cart.length === 0) {
        state.rid = null
      }
      localStorage.setItem("mealsbusCart", JSON.stringify(state))
    },

    incrementQuantity: (state, { payload }) => {
      const isFound = state.cart.find((item) => item.id === payload)
      if (isFound) {
        state.cart = state.cart.map((item) =>
          item.id === payload ? { ...item, quantity: item.quantity + 1 } : item,
        )
        localStorage.setItem("mealsbusCart", JSON.stringify(state))
      }
    },

    decrementQuantity: (state, { payload }) => {
      const isFound = state.cart.find((item) => item.id === payload)
      if (isFound) {
        state.cart = state.cart.map((item) =>
          item.id === payload
            ? { ...item, quantity: item.quantity > 2 ? item.quantity - 1 : 1 }
            : item,
        )
        localStorage.setItem("mealsbusCart", JSON.stringify(state))
      }
    },

    getCartTotal: (state) => {
      const { totalPrice, totalQuantity } = state.cart.reduce(
        (cartTotal, cartItem) => {
          const { price, quantity } = cartItem
          const itemTotal = price * quantity
          cartTotal.totalPrice += itemTotal
          cartTotal.totalQuantity += quantity
          return cartTotal
        },
        { totalPrice: 0, totalQuantity: 0 },
      )
      state.totalQuantity = totalQuantity
      state.totalPrice = totalPrice.toFixed(2)
    },
  },
})

export const {
  addItem,
  removeItem,
  incrementQuantity,
  decrementQuantity,
  getCartTotal,
  addItemWithQuantity,
} = cartSlice.actions
export default cartSlice.reducer
