import Login from "../pages/website/login"
import Register from "../pages/website/register"
import Forgot from "../pages/website/forgot"
import Reset from "../pages/website/reset"
import Home from "../pages/website/home"
import HomeKitchen from "../pages/website/kitchens"
import FoodTrucks from "../pages/website/trucks"
import Restaurants from "../pages/website/restaurants"
import Others from "../pages/website/others"
import Store from "../pages/website/store"
import ProductDetail from "../pages/website/product_detail"
import Cart from "../pages/website/cart"
import About from "../pages/website/about"
import TermsAndConditions from "../pages/website/terms_and_conditions"
import Contact from "../pages/website/contact"
import VerifyAccount from "../pages/website/verifyAccount"

import Dashboard from "../pages/dashboard/Home"
import Profile from "../pages/dashboard/Profile"
import Orders from "../pages/dashboard/Orders"
import Account from "../pages/dashboard/Account"
import OrderDescription from "../pages/dashboard/order_description"

export const DASHBOARD_ROUTES = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/dashboard/orders",
    component: Orders,
  },
  {
    path: "/dashboard/order/:id",
    component: OrderDescription,
  },
  {
    path: "/dashboard/profile",
    component: Profile,
  },
  {
    path: "/dashboard/settings",
    component: Account,
  },
]

export const ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/home-kitchen",
    component: HomeKitchen,
  },
  {
    path: "/restaurant",
    component: Restaurants,
  },
  {
    path: "/food-truck",
    component: FoodTrucks,
  },
  {
    path: "/others",
    component: Others,
  },
  {
    path: "/store/:name",
    component: Store,
  },
  {
    path: "/product-detail/:id",
    component: ProductDetail,
  },
  {
    path: "/cart",
    component: Cart,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
  },
]

export const AUTH_ROUTES = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/verify",
    component: VerifyAccount,
  },
  {
    path: "/forgot",
    component: Forgot,
  },
  {
    path: "/reset",
    component: Reset,
  },
]
