import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import "./App.css"
import React, { useLayoutEffect } from "react"
import { Route, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { ToastContainer } from "react-toastify"
import Cookies from "js-cookie"
import decode from "jwt-decode"
import { AUTH_ROUTES, DASHBOARD_ROUTES, ROUTES } from "./utils/routes"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "./utils/constants"
import ProtectedAuth from "./protectedAuth"
import ProtectedRoute from "./protectedRoute"
import { getMe } from "./store/slices/userSlice"
import Checkout from "./pages/website/checkout"
import PaymentSuccess from "./components/website/payment_success/payment_success"

function App() {
  const location = useLocation()
  const dispatch = useDispatch()

  const verifyToken = () => {
    const token = Cookies.get(JWT_STORAGE_NAME)
    if (token) {
      const decode_token = decode(token)
      if (
        decode_token.exp * 1000 < new Date().getTime() ||
        Cookies.get(JWT_STORAGE_NAME) === undefined ||
        localStorage.getItem(LOCAL_STORAGE_NAME) === undefined ||
        localStorage.getItem(LOCAL_STORAGE_NAME) === "undefined"
      ) {
        Cookies.remove(JWT_STORAGE_NAME)
        localStorage.removeItem(JWT_STORAGE_NAME)
        localStorage.removeItem(LOCAL_STORAGE_NAME)
        window.location.reload()
      }
    }
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    verifyToken()
  }, [location.pathname])

  useLayoutEffect(() => {
    if (Cookies.get(JWT_STORAGE_NAME) !== undefined) {
      dispatch(getMe())
    }
  }, [])

  return (
    <>
      {ROUTES.map((route) => (
        <Route
          exact
          {...route}
        />
      ))}
      {AUTH_ROUTES.map((route) => (
        <ProtectedAuth
          exact
          {...route}
        />
      ))}
      <ProtectedRoute
        exact
        path="/checkout"
        component={Checkout}
      />
      <ProtectedRoute
        exact
        path="/success/:id"
        component={PaymentSuccess}
      />
      {DASHBOARD_ROUTES.map((route) => (
        <ProtectedRoute
          exact
          {...route}
        />
      ))}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme={"light"}
      />
    </>
  )
}

export default App
