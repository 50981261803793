import "./sidebar.css"
import { useDispatch } from "react-redux"
import { addActiveData } from "../../../../store/slices/storeSlice"

const Sidebar = ({ activeCategory, categories }) => {
  const dispatch = useDispatch()
  return (
    <div className="products_type">
      {categories.map(({ id, name }) => (
        <a
          key={id}
          onClick={() => dispatch(addActiveData({ id, name }))}
          style={{ fontWeight: activeCategory.id === id ? 600 : 400 }}
        >
          {name}
        </a>
      ))}
    </div>
  )
}

export default Sidebar
