import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"
import ContactSection from "../../components/website/contact/contact"
import React from "react";

function Contact() {
    return(
        <>
            <Header/>
            <ContactSection/>
            <Footer/>
        </>
    )
}

export default Contact