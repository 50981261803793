import { createSlice } from "@reduxjs/toolkit"

const storeSlice = createSlice({
  name: "storeSlice",
  initialState: { store: null, activeProducts: [], activeCategory: null },
  reducers: {
    addData: (state, { payload }) => {
      return { ...state, store: payload }
    },
    activeCategory: (state, { payload }) => {
      return { ...state, activeCategory: payload }
    },
    activeProducts: (state, { payload }) => {
      return { ...state, activeProducts: payload }
    },
    addActiveData: (state, { payload }) => {
      const products = state.store.products.filter(
        (item) => item.cid === payload.id,
      )
      return {
        ...state,
        activeProducts: products,
        activeCategory: { id: payload.id, name: payload.name },
      }
    },
  },
})

export const { addData, activeCategory, activeProducts, addActiveData } =
  storeSlice.actions
export default storeSlice.reducer
