import "./profile.css"
import { useState, useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import { CountryDropdown } from "react-country-region-selector"
import ClipLoader from "react-spinners/ClipLoader"
import Button from "../../../common/Button"
import camera from "../../../assets/icons/camera.svg"
import avatar from "../../../assets/avatar.png"
import Api from "../../../api/api"
import { Notifications } from "../../../helper/notifications"
import { LOADER_OVERRIDE } from "../../../utils/constants"
import Loader from "../../../components/dashboard/loader/loader"
import { getMe } from "../../../store/slices/userSlice"

const Profile = () => {
  const dispatch = useDispatch()
  const [isDisable, setIsDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    avatar: "",
    avatarURL: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    country: "",
  })

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)

    try {
      const res = await Api.updateProfile(data)
      if (res.status === 200) {
        Notifications("success", res.data.message)
        dispatch(getMe())
      }
    } finally {
      setIsDisable(false)
    }
  }

  const setImageHandle = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setData((prev) => ({
          ...prev,
          avatarURL: event.target.result,
          avatar: event.target.result,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const inputHandle = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  useLayoutEffect(() => {
    async function getMeProfile() {
      const res = await Api.getMeProfile()
      if (res.status === 200) {
        setData({
          avatarURL: res.data.data.avatar,
          name: res.data.data.name,
          email: res.data.data.email,
          phone: res.data.data.phone,
          address: res.data.data.address,
          city: res.data.data.city,
          country: res.data.data.country,
        })
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      }
    }

    getMeProfile()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="profile_page_cont">
          <div className="c_card">
            <div className="title_3">Profile Setting</div>
            <br />
            <form
              onSubmit={submitHandle}
              className="profile_form"
            >
              <Picture
                image={data.avatarURL}
                setImageHandle={setImageHandle}
              />
              <br />
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="name"
                    value={data?.name}
                    onChange={inputHandle}
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="email"
                    name="email"
                    value={data?.email}
                    onChange={inputHandle}
                    disabled
                    placeholder="Email Address"
                    required
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="phone"
                    value={data?.phone}
                    onChange={inputHandle}
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="address"
                    value={data?.address}
                    onChange={inputHandle}
                    placeholder="Address"
                    required
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="city"
                    value={data?.city}
                    onChange={inputHandle}
                    placeholder="City"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <CountryDropdown
                    name="country"
                    value={data?.country}
                    onChange={(val) =>
                      setData((prev) => ({
                        ...prev,
                        country: val,
                      }))
                    }
                  />
                </div>
              </div>

              <Button
                disabled={isDisable}
                type="submit"
                margin="0 0 8px 0"
              >
                {!isDisable ? (
                  "Update"
                ) : (
                  <ClipLoader
                    color="#FFFFFF"
                    loading={true}
                    cssOverride={LOADER_OVERRIDE}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </Button>
              {/* <div className="profile_link">
            <a>
              <img
                src={deleteIcon}
                alt="Delete Account"
              />
              <span>Delete Account</span>
            </a>
          </div> */}
            </form>
          </div>
        </div>
      )}
    </>
  )
}

const Picture = ({ image, setImageHandle }) => {
  return (
    <div className="profile_picture">
      <label htmlFor="avatar">
        <img
          src={image}
          alt="avatar"
        />
        <span>
          <img
            src={camera}
            alt=""
          />
        </span>
      </label>
      <input
        type="file"
        id="avatar"
        name="imageURL"
        accept="image/*"
        onChange={setImageHandle}
      />
    </div>
  )
}

export default Profile
