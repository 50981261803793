import React from "react"
import VerifySection from "../../components/website/verifyCode/verify"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"

function VerifyAccount() {
  return (
    <>
      <Header />
      <VerifySection />
      <Footer />
    </>
  )
}

export default VerifyAccount
