import "./about.css"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import statement from "../../../assets/aboutstatement.jpg"
import about from "../../../assets/aboutus.jpg"

function AboutSection() {
  return (
    <>
      <div className="wrapper width100P">
        <section className="section">
          <h1 className="text-center head ctheme fw-bold">About Us</h1>
          <Container>
            <Row>
              <Col
                lg={7}
                md={12}
                xs={12}
              >
                <p className="desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                  amet, assumenda aut ducimus eius eos et ipsa magni maiores
                  minima natus numquam porro quae quaerat quos recusandae
                  reiciendis rerum, temporibus.
                </p>
                <p className="desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                  amet, assumenda aut ducimus eius eos et ipsa magni maiores
                  minima natus numquam porro quae quaerat quos recusandae
                  reiciendis rerum, temporibus.
                </p>
                <p className="desc">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                  amet, assumenda aut ducimus eius eos et ipsa magni maiores
                  minima natus numquam porro quae quaerat quos recusandae
                  reiciendis rerum, temporibus.
                </p>
              </Col>
              <Col
                lg={5}
                md={12}
                xs={12}
              >
                <p className="text-center">
                  <img
                    src={about}
                    alt=""
                    className="aboutImg width100P"
                  />
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section btheme">
          <h1 className="text-center head cWhite fw-bold">
            Mealsbus Statement
          </h1>
          <Container>
            <Row>
              <Col
                lg={6}
                md={12}
                xs={12}
              >
                <p className="text-center">
                  <img
                    src={statement}
                    alt=""
                    className="aboutImg width100P"
                  />
                </p>
              </Col>
              <Col
                lg={6}
                md={12}
                xs={12}
              >
                <h3 className="subHead fw-bold cWhite">Mission</h3>
                <p className="desc cWhite">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Asperiores beatae commodi deleniti, dolore doloremque eius
                  expedita harum incidunt ipsum libero maxime obcaecati optio
                  quasi sapiente similique? Beatae hic officiis quisquam?
                </p>
                <br />
                <h3 className="subHead fw-bold cWhite">Vision</h3>
                <p className="desc cWhite">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Doloribus dolorum ducimus earum illum laborum molestias
                  officia ullam voluptates. Animi consectetur consequuntur
                  dolore illo in magni necessitatibus officiis qui sit voluptas.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section">
          <h1 className="text-center head ctheme fw-bold">Why Us</h1>
          <Container fluid>
            <Row>
              <Col
                lg={6}
                md={6}
                xs={12}
              >
                <div className="btheme br10 p5 whyUsWrapper">
                  <h3 className="subHead fw-bold cWhite">
                    <i className="bi bi-shop whyUsIcons"></i> Lorem
                  </h3>
                  <br />
                  <p className="desc cWhite">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Adipisci architecto commodi ea eos inventore iusto laborum
                    libero obcaecati odit, omnis porro quae quam quos ratione
                    repellendus sunt voluptas? At, tenetur.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                xs={12}
              >
                <div className="btheme br10 p5 whyUsWrapper">
                  <h3 className="subHead fw-bold cWhite">
                    <i className="bi bi-cart-check-fill whyUsIcons"></i> Lorem
                  </h3>
                  <br />
                  <p className="desc cWhite">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Adipisci architecto commodi ea eos inventore iusto laborum
                    libero obcaecati odit, omnis porro quae quam quos ratione
                    repellendus sunt voluptas? At, tenetur.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                xs={12}
              >
                <div className="btheme br10 p5 whyUsWrapper">
                  <h3 className="subHead fw-bold cWhite">
                    <i className="bi bi-shop whyUsIcons"></i> Lorem
                  </h3>
                  <br />
                  <p className="desc cWhite">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Adipisci architecto commodi ea eos inventore iusto laborum
                    libero obcaecati odit, omnis porro quae quam quos ratione
                    repellendus sunt voluptas? At, tenetur.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                xs={12}
              >
                <div className="btheme br10 p5 whyUsWrapper">
                  <h3 className="subHead fw-bold cWhite">
                    <i className="bi bi-shop whyUsIcons"></i> Lorem
                  </h3>
                  <br />
                  <p className="desc cWhite">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Adipisci architecto commodi ea eos inventore iusto laborum
                    libero obcaecati odit, omnis porro quae quam quos ratione
                    repellendus sunt voluptas? At, tenetur.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                xs={12}
              >
                <div className="btheme br10 p5 whyUsWrapper">
                  <h3 className="subHead fw-bold cWhite">
                    <i className="bi bi-cart-check-fill whyUsIcons"></i> Lorem
                  </h3>
                  <br />
                  <p className="desc cWhite">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Adipisci architecto commodi ea eos inventore iusto laborum
                    libero obcaecati odit, omnis porro quae quam quos ratione
                    repellendus sunt voluptas? At, tenetur.
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                xs={12}
              >
                <div className="btheme br10 p5 whyUsWrapper">
                  <h3 className="subHead fw-bold cWhite">
                    <i className="bi bi-shop whyUsIcons"></i> Lorem
                  </h3>
                  <br />
                  <p className="desc cWhite">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Adipisci architecto commodi ea eos inventore iusto laborum
                    libero obcaecati odit, omnis porro quae quam quos ratione
                    repellendus sunt voluptas? At, tenetur.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section btheme">
          <h1 className="text-center head cWhite fw-bold">FAQ's</h1>
          <Container>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Accordion Item #3</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Accordion Item #4</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

export default AboutSection
