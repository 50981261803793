import './footer.css'
import { Link } from 'react-router-dom'
import logo from '../../../assets/logo1.svg'

function Footer() {
    return (
      <footer>
        <div className="container">
          <div className="row m-0 footer-top">
            <div className="col-12 col-md-3 footer-col">
              <img
                className="footer_logo logo"
                src={logo}
                alt=""
              />
            </div>
            <div className="col-6 col-md-3 footer-col">
              <h6 className="footer-title">Learn More</h6>
              <div className="footer-links">
                <Link to="/about">About Us</Link>
                <Link to="/about">FAQ</Link>
                <Link to="/terms-and-conditions">Privacy Policy</Link>
                <Link to="/terms-and-conditions">Terms of Service</Link>
              </div>
            </div>
            <div className="col-6 col-md-3 footer-col">
              <h6 className="footer-title">My Account</h6>
              <div className="footer-links">
                <Link to="/dashboard">My Account</Link>
                <Link to="/cart">Cart</Link>
                <Link to="/contact">Contact</Link>
              </div>
            </div>
            <div className="col-6 col-md-3 footer-col">
              <h6 className="footer-title">Social Links</h6>
              <div className="footer-links">
                <Link to="/">Facebook</Link>
                <Link to="/">Instagram</Link>
                <Link to="/">Youtube</Link>
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="row m-0">
              <div className="col-12 text-center">
                <p className="copyright">
                  Copyright © {new Date().getFullYear()}{" "}
                  <Link to="/">Mealsbus</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
}

export default Footer