import "./card.css"
import { Link } from "react-router-dom"

const RestaurantCard = ({ name, banner, logo, id, rating, ratingCount }) => {
  return (
    <div
      className="restaurant_card h-100"
      key={id}
    >
      <Link
        to={`/store/${name}`}
        className="h-100"
      >
        <div className="card_image w-100 h-100">
          <img
            src={banner}
            style={{ aspectRatio: 4.3 / 2.4 }}
            alt={name}
          />
        </div>
        <div className="card_content">
          <div>
            <div>
              <img
                className="restaurant_logo"
                src={logo}
                alt={name}
              />
              <p className="restaurant_name">{name}</p>
            </div>
            <span className="rest_rating">
              <i className="bi bi-star-fill"></i> {rating} ({ratingCount})
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default RestaurantCard
