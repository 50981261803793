import React from "react"
import ProductDetailSection from "../../components/website/product_description/product_detail"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"

function ProductDetail() {
  return (
    <>
      <Header />
      <ProductDetailSection />
      <Footer />
    </>
  )
}

export default ProductDetail
