import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Api from "../../../api/api"
import { Notifications } from "../../../helper/notifications"
import { LOADER_OVERRIDE } from "../../../utils/constants"
import logo from "../../../assets/logo1.svg"

function ForgotSection() {
  const history = useHistory()
  const [data, setData] = useState({ email: "" })
  const [isDisable, setIsDisable] = useState(false)

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)

    const res = await Api.forgetPassword(data)
    if (res.status === 200) {
      Notifications("success", res.data.message)
      history.push("/reset")
    }
    setIsDisable(false)
  }

  return (
    <>
      <div className="loginWrapper">
        <form
          onSubmit={submitHandle}
          className="formWrapper br10"
        >
          <p className="loginLogoWrapper text-center">
            <img
              src={logo}
              alt=""
              className="loginLogoImg"
            />
          </p>
          <br />
          <h2 className="loginHead text-center fw-bold">Forgot Password</h2>
          <br />
          <br />
          <Box>
            <TextField
              className="width100P"
              color="warning"
              id="outlined-basic"
              label="Email"
              type="email"
              name="email"
              value={data.email}
              onChange={inputHandle}
              variant="outlined"
              required
            />
            <br />
            <br />
          </Box>
          <br />
          <p className="text-center">
            <button
              disabled={isDisable}
              className="submitLoginBtn btheme outlineNone borderNone br100 cWhite"
            >
              {!isDisable ? (
                "Forgot"
              ) : (
                <ClipLoader
                  color="#FFFFFF"
                  loading={true}
                  cssOverride={LOADER_OVERRIDE}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </button>
          </p>
          <br />
          <p className="text-center">
            Don't have an account ?{" "}
            <Link to={"/register"}>
              <span className="ctheme pointer">Create one</span>
            </Link>
          </p>
        </form>
      </div>
    </>
  )
}

export default ForgotSection
