import { Link, useParams } from "react-router-dom"
import party from "../../../assets/party.gif"
import Header from "../header/header"
import Footer from "../footer/footer"

function PaymentSuccess() {
  const { id } = useParams()

  return (
    <>
      <Header />
      <div style={{ paddingBottom: "2.5rem", backgroundColor: "#8080801c" }}>
        <div>
          <p className="text-center">
            <img
              style={{ mixBlendMode: "multiply" }}
              src={party}
              alt=""
            />
          </p>
          <br />
          <p className="text-center">
            <h5>Your order has been placed</h5>
          </p>
          <p className="text-center">
            <h5 className="fw-bold">Order# {id}</h5>
            <Link
              to={`/dashboard/order/${id}`}
              style={{
                color: "var(--primary)",
                fontSize: 14,
                textDecoration: "underline",
              }}
            >
              See Details
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PaymentSuccess
