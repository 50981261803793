import "./contact.css"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import TextField from "@mui/material/TextField"
import support from "../../../assets/aboutsupport.jpg"

function ContactSection() {
  return (
    <>
      <section
        className="section"
        style={{ background: "var(--bg_light)" }}
      >
        <h1 className="text-center head ctheme fw-bold">Contact Us</h1>
        <Container>
          <Row>
            <Col
              lg={5}
              md={12}
              xs={12}
            >
              <p className="text-center">
                <img
                  src={support}
                  alt=""
                  className="aboutImg width100P"
                />
              </p>
            </Col>

            <Col
              lg={7}
              md={12}
              xs={12}
            >
              <div className="contactDiv">
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                />
                <br />
                <br />
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                />
                <br />
                <br />
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={6}
                  defaultValue=""
                />
                <br />
                <br />
                <p className="text-center">
                  <button className="submitLoginBtn btheme outlineNone borderNone br100 cWhite">
                    Send
                  </button>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ContactSection
