import React from "react"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"
import CartSection from "../../components/website/cart/cart"

function Cart() {
  return (
    <>
      <Header />
      <CartSection />
      <Footer />
    </>
  )
}

export default Cart
