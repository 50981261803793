import React, { useCallback, useLayoutEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import moment from "moment"
import { Container, Row, Col } from "react-bootstrap"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import EyeIcon from "@mui/icons-material/RemoveRedEyeSharp"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import Loader from "../../../components/dashboard/loader/loader"
import Api from "../../../api/api"
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function OrderSection() {
  const user = useSelector((state) => state.user)
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const options = {
    search: true,
    download: true,
    print: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none", // toggle checkbox
  }

  const columns = [
    {
      name: "id",
      label: "Order ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "paymentId",
      label: "Payment ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "restaurant",
      label: "Restaurant",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (restaurant) => {
          return <span>{restaurant.storeName}</span>
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (price) => {
          return <span>${price}</span>
        },
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (order_status) => {
          const style = {
            padding: "4px 8px",
            fontSize: "12px",
            borderRadius: "5px",
            fontWeight: "bold",
            background:
              order_status === "dispatched"
                ? "rgba(145, 158, 171, 0.16)"
                : order_status === "pending"
                ? "rgba(255, 171, 0, 0.16)"
                : order_status === "cancelled"
                ? "rgba(255, 86, 48, 0.16)"
                : order_status === "delivered"
                ? "rgba(0, 184, 217, 0.16)"
                : "rgba(54, 179, 126, 0.16)",
            color:
              order_status === "dispatched"
                ? "rgb(33, 43, 54)"
                : order_status === "pending"
                ? "rgb(183, 110, 0)"
                : order_status === "cancelled"
                ? "rgb(183, 29, 24)"
                : order_status === "delivered"
                ? "rgb(0, 108, 156)"
                : "rgb(27, 128, 106)",
          }
          return <span style={style}> {order_status}</span>
        },
      },
    },
    {
      name: "createdAt",
      label: "Time Left",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (timeString, tableMeta) => {
          const updatedTime = moment(timeString)
          const futureTime = updatedTime.add(45, "minutes")
          const remainingTimeInMinutes = futureTime.diff(moment(), "minutes")
          return (
            <>
              {["completed", "cancelled"].includes(tableMeta.rowData[5]) ? (
                "-"
              ) : remainingTimeInMinutes > 0 ? (
                <span>{remainingTimeInMinutes} min</span>
              ) : (
                "0 Min"
              )}
            </>
          )
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (time) => {
          const dateTime = new Date(time)
          return <span>{dateTime.toLocaleString()}</span>
        },
      },
    },
    {
      name: "id",
      label: "Detail",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (id) => {
          return (
            <Link to={`/dashboard/order/${id}`}>
              <EyeIcon className="pointer" />
            </Link>
          )
        },
      },
    },
  ]

  const getOrders = useCallback(async () => {
    const res = await Api.getOrders(user.id)
    if (res.status === 200) {
      setOrders(res.data.data)
      setIsLoading(false)
    }
  }, [setOrders])

  useLayoutEffect(() => {
    getOrders()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="c_card">
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={8}
                md={8}
                xs={8}
              >
                <h2 className="text-left">Orders</h2>
              </Col>
              <Col
                lg={4}
                md={4}
                xs={4}
              >
                {/*<p className="text-right"><BTN margin="0 5px 5px 0" onClick={()=>{setAddModalShow(true)}}>Add User</BTN></p>*/}
              </Col>
            </Row>
          </Container>
          <br />

          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                data={orders}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </CacheProvider>
        </div>
      )}
    </>
  )
}

export default OrderSection
