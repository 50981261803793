import styled from "styled-components"

const Button = styled.button`
  border-radius: var(--border_radius);
  background: var(--primary);
  padding: 9px 14px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  border: 0;
  width: ${({ width }) => width || "auto"};
  margin: ${({ margin }) => margin || "0"};
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export default Button
