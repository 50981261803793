import { Redirect, Route } from "react-router-dom"
import Cookies from "js-cookie"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "./utils/constants"

export default function ProtectedRoute({ component: Cmp, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get(JWT_STORAGE_NAME) !== undefined &&
        localStorage.getItem(LOCAL_STORAGE_NAME) ? (
          <Cmp {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}
