const Cities = () => {
    return (
      <div
        className="cities_section p_tb"
        style={{ background: "var(--bg_light)" }}
      >
        <div className="container">
          <h2 className="fw-bold">
            <i className="bi bi-geo-alt"></i>Cities near me
          </h2>
          <div
            className="row w-100 m-0 mt-4"
            style={{ rowGap: 16 }}
          >
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
            <div className="col-6 col-sm-4 col-lg-3">Lorem, ipsum</div>
          </div>
        </div>
      </div>
    )
}

export default Cities