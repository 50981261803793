import "./hero.css"
import { memo, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"

const Hero = ({ products }) => {
  const [query, setQuery] = useState("")
  const [showSearch, setShowSearch] = useState(false)
  const showSearchRef = useRef()

  return (
    <div className="hero_section p_tb">
      <div className="container h-100">
        <div className="hero_cont">
          <h1 className="title">Discover the best meal at Mealsbus</h1>
          <form
            className="hero_input_wrapper mt-3"
            onClick={(e) => e.preventDefault()}
          >
            <input
              onClick={() => setShowSearch(true)}
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for restaurant, cuisine or a dish"
            />
            <button className="btn_primary">
              <i className="bi bi-search"></i>{" "}
            </button>
            {showSearch && (
              <Search
                products={products?.filter((item) => {
                  if (!query) return item
                  if (
                    item.name.toLowerCase().includes(query.toLowerCase()) ||
                    item.restaurant.storeName
                      .toLowerCase()
                      .includes(query.toLowerCase())
                  ) {
                    return { ...item }
                  }
                })}
                setShowSearch={setShowSearch}
                showSearchRef={showSearchRef}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

const Search = memo(({ products, showSearchRef, setShowSearch }) => {
  useEffect(() => {
    let handler = (e) => {
      if (!showSearchRef.current.contains(e.target)) {
        setShowSearch(false)
      }
    }

    document.addEventListener("mousedown", handler)
    return () => document.removeEventListener("mousedown", handler)
  }, [])

  return (
    <div
      className="search_wrapper"
      ref={showSearchRef}
    >
      {products.map((item) => (
        <Link
          to={`/product-detail/${item.id}`}
          key={item.id}
          className="products_all"
        >
          <img
            src={item.imgUrl}
            alt={item.name}
          />
          <p>
            <span>{item.name}</span>
            <span>{item.restaurant.storeName}</span>
          </p>
        </Link>
      ))}
    </div>
  )
})

export default Hero
