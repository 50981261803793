import { configureStore } from "@reduxjs/toolkit"
import sidebarSlice from "./slices/sidebarSlice"
import userSlice from "./slices/userSlice"
import storeSlice from "./slices/storeSlice"
import cartSlice from "./slices/cartSlice"

export const store = configureStore({
  reducer: {
    store: storeSlice,
    sidebar: sidebarSlice,
    user: userSlice,
    cart: cartSlice,
  },
})
