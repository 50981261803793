import React from "react";
import {Col, Container, Row} from "react-bootstrap";


function TermsAndConditionsSection() {
    return(
        <>
            <section className="section">
                <h1 className="text-center head ctheme fw-bold">Terms and Conditions</h1>
                <Container>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <h3 className="subHead ctheme">Heading 1</h3>
                            <p className="desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam aperiam, commodi consequatur dolore enim ex, hic id ipsa iste libero nam, natus quas quos repellendus rerum sunt tempora temporibus.
                                <ul>
                                    <li>lorem</li>
                                    <li>lorem</li>
                                    <li>lorem</li>
                                </ul>
                            </p>
                            <br/>
                            <h3 className="subHead ctheme">Heading 2</h3>
                            <p className="desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam aperiam, commodi consequatur dolore enim ex, hic id ipsa iste libero nam, natus quas quos repellendus rerum sunt tempora temporibus.
                                <ul>
                                    <li>lorem</li>
                                    <li>lorem</li>
                                    <li>lorem</li>
                                </ul>
                            </p>
                            <br/>
                            <h3 className="subHead ctheme">Heading 3</h3>
                            <p className="desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam aperiam, commodi consequatur dolore enim ex, hic id ipsa iste libero nam, natus quas quos repellendus rerum sunt tempora temporibus.
                                <ul>
                                    <li>lorem</li>
                                    <li>lorem</li>
                                    <li>lorem</li>
                                </ul>
                            </p>
                            <br/>
                            <h3 className="subHead ctheme">Heading 4</h3>
                            <p className="desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam aperiam, commodi consequatur dolore enim ex, hic id ipsa iste libero nam, natus quas quos repellendus rerum sunt tempora temporibus.
                                <ul>
                                    <li>lorem</li>
                                    <li>lorem</li>
                                    <li>lorem</li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                </Container>

                <br/>
                <br/>

                <h1 className="text-center head ctheme fw-bold">Privacy Policy</h1>
                <Container>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <h3 className="subHead ctheme">Heading 1</h3>
                            <p className="desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam aperiam, commodi consequatur dolore enim ex, hic id ipsa iste libero nam, natus quas quos repellendus rerum sunt tempora temporibus.
                            </p>
                            <br/>
                            <h3 className="subHead ctheme">Heading 2</h3>
                            <p className="desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam aperiam, commodi consequatur dolore enim ex, hic id ipsa iste libero nam, natus quas quos repellendus rerum sunt tempora temporibus.
                            </p>
                            <br/>
                            <h3 className="subHead ctheme">Heading 3</h3>
                            <p className="desc">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam aperiam, commodi consequatur dolore enim ex, hic id ipsa iste libero nam, natus quas quos repellendus rerum sunt tempora temporibus.
                            </p>
                            <br/>
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    )
}

export default TermsAndConditionsSection