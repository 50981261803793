import React from "react";
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"
import CheckoutSection from "../../components/website/checkout/checkout"

function Checkout() {
    return(
        <>
            <Header/>
            <CheckoutSection/>
            <Footer/>
        </>
    )
}

export default Checkout