export const JWT_STORAGE_NAME = "mealsbusToken"
export const LOCAL_STORAGE_NAME = "mealsbusUser"
export const CLIENT_DETAIL = "mealsbusUserDetail"
// export const CLIENT_LINK = "http://localhost:3000"
// export const SELLER_LINK = "http://localhost:3001"
export const CLIENT_LINK = "https://mealsbus.com"
export const SELLER_LINK = "https://vendor.mealsbus.com"

export const LOADER_OVERRIDE = {
  display: "block",
  margin: "0 auto",
  border: "3px solid",
  borderColor: "#FFFFFF",
}
