import React, { useCallback, useLayoutEffect, useState } from "react"
import Hero from "../../components/website/hero/hero"
import HomeKitchen from "../../components/website/kitchens/kitchens"
import FoodTruck from "../../components/website/trucks/trucks"
import Cities from "../../components/website/cities/cities"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"
import Restaurants from "../../components/website/restaurant/restaurants"
import Api from "../../api/api"

const Home = () => {
  const [products, setProducts] = useState()
  const [data, setData] = useState({
    home_kitchen: [],
    food_truck: [],
    restaurant: [],
  })

  const getStores = useCallback(async () => {
    const res = await Api.getStores()
    if (res.status === 200) {
      setData({
        home_kitchen: res.data.data.filter(
          (item) => item.businessType === "home-kitchen",
        ),
        food_truck: res.data.data.filter(
          (item) => item.businessType === "food-truck",
        ),
        restaurant: res.data.data.filter(
          (item) => item.businessType === "restaurant",
        ),
      })
    }
  }, [])

  const getAllProducts = useCallback(async () => {
    const res = await Api.getAllProducts()
    if (res.status === 200) {
      setProducts(res.data.data)
    }
  }, [])

  useLayoutEffect(() => {
    getStores()
    getAllProducts()
  }, [])

  return (
    <div className="homepage">
      <Header />
      <Hero products={products} />
      <HomeKitchen stores={data.home_kitchen} />
      <Restaurants stores={data.restaurant} />
      <FoodTruck stores={data.food_truck} />
      {/* <Cities /> */}
      <Footer />
    </div>
  )
}

export default Home
