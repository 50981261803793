import "./notifications.css"
import { useEffect } from "react"
import moment from "moment"
import Api from "../../../api/api"

const Notifications = ({ notifications, showNotiRef, setShowNoti }) => {
  const updateStatus = async (id, link, seen) => {
    if (seen) {
      window.location.href = link
    } else {
      const res = await Api.updateNotification(id)
      if (res.status === 200) {
        window.location.href = link
      }
    }
  }

  useEffect(() => {
    let handler = (e) => {
      if (!showNotiRef.current.contains(e.target)) {
        setShowNoti(false)
      }
    }

    document.addEventListener("mousedown", handler)
    return () => document.removeEventListener("mousedown", handler)
  }, [])

  return (
    <div
      className="notifications_wrapper"
      ref={showNotiRef}
    >
      {notifications.map((item) => (
        <a
          key={item.id}
          className={!item.seen && "active"}
          onClick={() => updateStatus(item.id, item.link, item.seen)}
        >
          <p>{item.message}</p>
          <span>{moment(item.createdAt).fromNow()}</span>
        </a>
      ))}
    </div>
  )
}

export default Notifications
