import "./banner.css"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "../../../../layout/modal/modal"
import { isOpenHandle } from "../../../../helper/isOpenCheck"

const Banner = () => {
  const { store } = useSelector((state) => state.store)
  const [showLocation, setShowLocation] = useState(false)

  return (
    <div className="rest_banner">
      <img
        // src="https://tb-static.uber.com/prod/image-proc/processed_images/dfe4b549a1e4a36629e8f84e6ad40e7c/3ac2b39ad528f8c8c5dc77c59abb683d.jpeg"
        src={store.banner}
        alt={store.storeName}
        className="banner_img"
      />
      <div className="banner_cont">
        <div className="container">
          <div className="d-flex mb-3">
            <img
              className="rest_logo"
              // src="https://product-assets.faasos.io/production/product/logo_image_1664197726473_LB%20Logo.jpg"
              src={store.avatar}
              alt={store.storeName}
            />
            <div className="banner_info">
              <h3 className="banner_title mb-1">{store.storeName}</h3>
              <span className="rest_rating">
                <i className="bi bi-star-fill"></i> {store.rating} (
                {store.ratingCount})
              </span>
              &nbsp;
              <span onClick={() => setShowLocation(true)}>
                <i className="bi bi-geo-alt-fill"></i>
              </span>
            </div>
          </div>
          <div className="rest_timing">
            <span style={{ transform: "translateY(-1px)" }}>
              <i className="bi bi-clock-fill"></i>
            </span>
            <Timing hours={store.hours} />
          </div>
          <div className="resto_taxes">
            <p>
              <span>Marketing Commission:</span>
              {store.marketingCommission}%
            </p>
            <p>
              <span>Delivery Commission:</span>
              {store.deliveryCommission}%
            </p>
            <p>
              <span>Processing Fee:</span>
              {store.processingFee}%
            </p>
            <p>
              <span>Other Charges:</span>
              {store.otherCharges}%
            </p>
            <p>
              <span>Sales Tax:</span>
              {store.salesTax}%
            </p>
          </div>
        </div>
      </div>
      {showLocation && (
        <CustomModal
          title="Location"
          onHide={() => setShowLocation(false)}
        >
          <p>{`${store.address}, ${store.city}, ${store.state}, ${store.country}`}</p>
        </CustomModal>
      )}
    </div>
  )
}

const Timing = ({ hours }) => {
  const today = new Date()
  const dayName = today.toLocaleString("en-US", { weekday: "long" })
  const [show, setShow] = useState(false)

  return (
    <div>
      <p
        onClick={() => setShow((prev) => !prev)}
        style={{ cursor: "pointer" }}
      >
        {hours.map(
          ({ label, from, to, isOpen }) =>
            label === dayName && (
              <span style={{ fontWeight: 600 }}>
                {isOpenHandle(label, from, to, isOpen)
                  ? `Open until ${to}`
                  : "Closed"}
              </span>
            ),
        )}
        &nbsp; <i className={`bi bi-${show ? "dash" : "plus"}-circle`}></i>
      </p>
      {show && (
        <>
          {hours.map(({ label, from, to, isOpen }) =>
            label === dayName ? (
              <div style={{ fontWeight: 700 }}>
                <p>{label}</p>{" "}
                <span>
                  {from}–{to}
                </span>
              </div>
            ) : (
              <div>
                <p>{label}</p>{" "}
                <span>
                  {from}–{to}
                </span>
              </div>
            ),
          )}
        </>
      )}
    </div>
  )
}
export default Banner
