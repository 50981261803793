import { createSlice } from "@reduxjs/toolkit"

const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState: window.innerWidth > 1024 ? true : false,
  reducers: {
    toggleSidebar: (state) => {
      return !state
    },
    hideSidebar: (state) => {
      return window.innerWidth > 1024 ? true : false
    },
  },
})

export const { toggleSidebar, hideSidebar } = sidebarSlice.actions
export default sidebarSlice.reducer
