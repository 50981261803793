import React from "react"
import { Link } from "react-router-dom"
import RestaurantCard from "../resto_card/card"

const FoodTruck = ({stores}) => {
  return (
    <div className="truck_section p_tb">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="fw-bold">
            <i className="bi bi-truck"></i> Food Trucks
          </h2>
          <Link
            to="/food-truck"
            style={{
              color: "var(--bg_secondary)",
              textDecoration: "underline",
            }}
          >
            Explore All
          </Link>
        </div>
        <div
          className="row w-100 m-0 mt-4"
          style={{ rowGap: 16 }}
        >
          {stores && stores.length > 0
            ? stores
                .map((item) => (
                  <div className="col-12 col-sm-6 col-lg-4">
                    <RestaurantCard
                      id={item.id}
                      name={item.storeName}
                      banner={item.banner}
                      logo={item.avatar}
                      rating={item.rating}
                      ratingCount={item.ratingCount}
                    />
                  </div>
                ))
                .slice(0, 3)
            : "No Data Available"}
        </div>
      </div>
    </div>
  )
}

export default FoodTruck
