export const DASHBOARD_LINKS = [
  {
    to: "/dashboard",
    title: "Dashboard",
  },
  {
    to: "/dashboard/orders",
    title: "Orders",
  },
  {
    to: "/dashboard/profile",
    title: "Profile Settings",
  },
  {
    to: "/dashboard/settings",
    title: "Account Settings",
  },
  // {
  //     to: "/history",
  //     title: "History",
  // },
]
