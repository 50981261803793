import "./usermenu.css"
import { memo, useEffect } from "react"
import Cookies from "js-cookie"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../../../utils/constants"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const Usermenu = ({
  avatar,
  name,
  email,
  showUserMenuRef,
  setShowUserMenu,
}) => {
  const logoutHandler = () => {
    Cookies.remove(JWT_STORAGE_NAME)
    localStorage.removeItem(JWT_STORAGE_NAME)
    localStorage.removeItem(LOCAL_STORAGE_NAME)
    window.location.reload()
  }

  useEffect(() => {
    let handler = (e) => {
      if (!showUserMenuRef.current.contains(e.target)) {
        setShowUserMenu(false)
      }
    }

    document.addEventListener("mousedown", handler)
    return () => document.removeEventListener("mousedown", handler)
  }, [])
  return (
    <div
      ref={showUserMenuRef}
      className="usermenu"
    >
      <div className="usermenu_head">
        <img
          src={avatar}
          alt=""
        />
        <div>
          <p>{name}</p>
          <span>{email}</span>
        </div>
      </div>
      <div className="usermenu_body">
        <div className="username_links">
          <Link to={`/dashboard`}>Dashboard</Link>
          <Link to={`/dashboard/orders`}>My Orders</Link>
          <Link to={`/dashboard/profile`}>Profile Settings</Link>
          <a onClick={logoutHandler}>
            <i className="bi bi-box-arrow-right"></i> Logout
          </a>
        </div>
      </div>
    </div>
  )
}

export default memo(Usermenu)
