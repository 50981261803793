import { useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import Button from "../../../common/Button"
import Api from "../../../api/api"
import { Notifications } from "../../../helper/notifications"
import { LOADER_OVERRIDE } from "../../../utils/constants"

const Account = () => {
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    prePassword: "",
    newPassword: "",
    confirmPassword: "",
  })

  const submitHandle = async (e) => {
    e.preventDefault()
    if (data.newPassword !== data.confirmPassword)
      return Notifications("warning", "Confirm Password doesn't match")

    setIsDisable(true)
    const res = await Api.updatePassword(data)
    try {
      if (res.status === 200) {
        Notifications("success", res.data.message)
      }
    } finally {
      setData({ prePassword: "", newPassword: "", confirmPassword: "" })
      setIsDisable(false)
    }
  }

  const inputHandle = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <div className="profile_page_cont">
      <div className="c_card">
        <div className="title_3">Account Setting</div>
        <br />
        <form
          onSubmit={submitHandle}
          className="profile_form"
        >
          <div className="profile_input_wrapper">
            <div className="input_wrapper">
              <input
                type="password"
                name="prePassword"
                value={data?.prePassword}
                onChange={inputHandle}
                placeholder="Previous Password"
                required
              />
            </div>
          </div>
          <div className="profile_input_wrapper">
            <div className="input_wrapper">
              <input
                type="password"
                name="newPassword"
                value={data?.newPassword}
                onChange={inputHandle}
                placeholder="New Password"
                required
              />
            </div>
          </div>
          <div className="profile_input_wrapper">
            <div className="input_wrapper">
              <input
                type="password"
                name="confirmPassword"
                value={data?.confirmPassword}
                onChange={inputHandle}
                placeholder="Confirm Password"
                required
              />
            </div>
          </div>

          <Button
            disabled={isDisable}
            type="submit"
            margin="0 0 8px 0"
          >
            {!isDisable ? (
              "Update"
            ) : (
              <ClipLoader
                color="#FFFFFF"
                loading={true}
                cssOverride={LOADER_OVERRIDE}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </Button>
        </form>
      </div>

    </div>
  )
}

export default Account
