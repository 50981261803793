import { useState, useEffect } from "react"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../utils/constants"
import Cookies from "js-cookie"

export default function useIsLogged() {
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (token !== undefined && Cookies.get(JWT_STORAGE_NAME) !== undefined) {
      setIsLogged(true)
    } else {
      setIsLogged(false)
    }
  }, [])

  return isLogged
}
