import React from "react";
import ForgotSection from "../../components/website/forgot/forgot"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"

function Forgot() {
    return(
        <>
            <Header />
            <ForgotSection/>
            <Footer />
        </>
    )
}

export default Forgot