import "./Sidebar.css"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { DASHBOARD_LINKS } from "../../../utils/links"
import logo from "../../../assets/logo1.svg"

const Sidebar = () => {
  const toggleSidebar = useSelector((state) => state.sidebar)
  const activeLink = (id) => {
    if (id === window.location.pathname) return "active"
  }

  return (
    <div
      className="sidebar"
      style={{ left: toggleSidebar ? "1rem" : "-280px" }}
    >
      <div className="c_card">
        <div
          className="logo"
          style={{ margin: "0 auto 10px auto" }}
        >
          <img
            src={logo}
            alt="Mealsbus Logo"
          />
        </div>
        <div className="sidebar_cont">
          {DASHBOARD_LINKS.map(({ to, title }) => (
            <Link
              to={to}
              key={to}
              className={`link ${activeLink(to)}`}
            >
              {title}
            </Link>
          ))}
          <Link
            to="/"
            className="link"
          >
            View Website
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
