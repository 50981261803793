import { useState } from "react"
import { useHistory } from "react-router-dom"
import ReactStars from "react-rating-stars-component"
import Button from "../../../common/Button"
import CustomModal from "../../../layout/modal/modal"
import Api from "../../../api/api"
import { SELLER_LINK } from "../../../utils/constants"

const AddReview = ({ oid, uid, rid, storeName, onHide }) => {
  const history = useHistory()
  const [isDisabled, setIsDisabled] = useState(false)
  const [data, setData] = useState({
    rating: 0,
    review: "",
  })

  const dataSubmitHandle = async (e) => {
    e.preventDefault()
    setIsDisabled(true)
    await Api.postReview({ oid, uid, rid, ...data })
    await Api.updateOrder({ id: oid, status: "completed" })
    await Api.postNotification({
      to_id: rid,
      to: "seller", //user, seller
      message: `Order #${oid} completed. View feedback.`,
      link: `${SELLER_LINK}/reviews`,
    })
    history.push("/dashboard")
  }

  return (
    <CustomModal
      title={"Add Feedback"}
      onHide={onHide}
    >
      <form onSubmit={dataSubmitHandle}>
        <p className="text-center">
          Rate your experience with <strong>{storeName}</strong>
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactStars
            count={5}
            value={data.rating}
            size={28}
            activeColor="#F3B455"
            onChange={(value) =>
              setData((prev) => ({ ...prev, rating: value }))
            }
          />
        </div>
        <div style={{ margin: "8px 0" }}>
          <input
            type="text"
            value={data.review}
            onChange={(e) =>
              setData((prev) => ({ ...prev, review: e.target.value }))
            }
            placeholder="Feedback"
            required
            style={{
              border: "1px solid lightgray",
              borderRadius: 8,
              fontSize: 15,
              padding: 10,
              width: "100%",
            }}
          />
        </div>
        <Button
          disabled={isDisabled}
          width="100%"
        >
          Submit
        </Button>
      </form>
    </CustomModal>
  )
}
export default AddReview
