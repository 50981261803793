import "./cart.css"
import React, { useEffect, useLayoutEffect, useState, useCallback } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import { useDispatch, useSelector } from "react-redux"
import Api from "../../../api/api"
import {
  decrementQuantity,
  getCartTotal,
  incrementQuantity,
  removeItem,
} from "../../../store/slices/cartSlice"

function CartSection() {
  const dispatch = useDispatch()
  const { cart, totalPrice: subTotal, rid } = useSelector((state) => state.cart)
  const [data, setData] = useState()

  const getStore = useCallback(async () => {
    const res = await Api.getStoresByID(rid)
    if (res.status === 200) {
      setData(res.data.data)
    }
  }, [setData])

  const totalPrice = () => {
    const taxes =
      data?.deliveryCommission +
      data?.marketingCommission +
      data?.processingFee +
      data?.otherCharges +
      data?.salesTax

    const totalAmount =
      (taxes * parseFloat(subTotal)) / 100 + parseFloat(subTotal)
    return totalAmount.toFixed(2)
  }

  useLayoutEffect(() => {
    if (cart?.length > 0) {
      getStore()
    }
  }, [])

  useEffect(() => {
    dispatch(getCartTotal())
  }, [cart])

  return (
    <>
      {cart.length === 0 ? (
        <section
          className="section"
          style={{
            background: "var(--bg_light)",
            textAlign: "center",
          }}
        >
          No Item Added
        </section>
      ) : (
        <section
          className="section"
          style={{ background: "var(--bg_light)", color: "white" }}
        >
          <Container fluid>
            <Row>
              <Col
                lg={8}
                md={12}
                xs={12}
              >
                {cart.map((item) => (
                  <div
                    className="cartItem btheme br5 p2"
                    key={item.id}
                  >
                    <Row>
                      <Col
                        lg={2}
                        md={2}
                        xs={0}
                      >
                        <img
                          src={item.imgUrl}
                          alt={item.name}
                          className="cartItmImg br5 width100P"
                        />
                      </Col>
                      <Col
                        lg={5}
                        md={5}
                        xs={6}
                      >
                        <span>{item.name}</span>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        xs={6}
                      >
                        <div style={{ textAlign: "end" }}>
                          ${item.price * item.quantity}
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        xs={0}
                      >
                        <InputGroup
                          size="sm"
                          className="mb-1"
                        >
                          <InputGroup.Text
                            onClick={() => dispatch(decrementQuantity(item.id))}
                            className="pointer"
                          >
                            <i className="bi bi-dash-lg"></i>
                          </InputGroup.Text>
                          <Form.Control
                            className="text-center"
                            value={item.quantity}
                            disabled
                            aria-label="Amount (to the nearest dollar)"
                          />
                          <InputGroup.Text
                            onClick={() => dispatch(incrementQuantity(item.id))}
                            className="pointer"
                          >
                            <i className="bi bi-plus-lg"></i>
                          </InputGroup.Text>
                        </InputGroup>
                        <p style={{ float: "right" }}>
                          <InputGroup
                            size="sm"
                            className="resDeleteBtn pointer"
                          >
                            <InputGroup.Text>
                              <i className="bi bi-trash3-fill"></i>
                            </InputGroup.Text>
                          </InputGroup>
                        </p>
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        xs={1}
                      >
                        <InputGroup
                          size="sm"
                          className="pcDeleteBtn pointer"
                        >
                          <InputGroup.Text
                            onClick={() => dispatch(removeItem(item.id))}
                          >
                            <i className="bi bi-trash3-fill"></i>
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>
              <Col
                lg={4}
                md={12}
                xs={12}
              >
                <div className="btheme p4 br5 cartsummary cWhite">
                  <h4 className="subsubHead fw-bold">Order Summary</h4>
                  <br />
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Marketing Commission</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{data?.marketingCommission}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Delivery Commission</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{data?.deliveryCommission}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Processing Fee</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{data?.processingFee}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Sales Tax</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{data?.salesTax}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Other Charges</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{data?.otherCharges}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Subtotal</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>${subTotal}</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead fw-bold">Total</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <h6 className="subsubsubHead fw-bold">${totalPrice()}</h6>
                    </Col>
                  </Row>
                  <br />
                  <p className="text-center">
                    <Link
                      to="/checkout"
                      className="btn btn-light"
                    >
                      Proceed to Checkout
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

export default CartSection
