import "./loader.css"
import ClipLoader from "react-spinners/ClipLoader"

const override = {
  display: "block",
  margin: "0 auto",
  border: "5px solid",
  borderColor: "#f5661d",
}

function Loader() {
  return (
    <div className="loader">
      <ClipLoader
        color="#FFFFFF"
        loading={true}
        cssOverride={override}
        size={80}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}

export default Loader
