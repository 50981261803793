import { useCallback, useLayoutEffect, useState } from "react"
import RestaurantCard from "../../components/website/resto_card/card"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"
import Api from "../../api/api"

const FoodTrucks = () => {
  const [data, setData] = useState([])

  const getStores = useCallback(async () => {
    const res = await Api.getStoresByType("food-truck")
    if (res.status === 200) {
      setData(res.data.data)
    }
  }, [])

  useLayoutEffect(() => {
    getStores()
  }, [])
  return (
    <>
      <Header />
      <div
        className="food_trucks p_tb"
        style={{ background: "var(--bg_light)" }}
      >
        <div className="container">
          <h2 className="fw-bold">
            <i className="bi bi-truck"></i> Food Trucks
          </h2>
          <div
            className="row m-0 w-100 mt-4"
            style={{ rowGap: 16 }}
          >
            {data && data.length > 0
              ? data.map((item) => (
                  <div className="col-12 col-sm-6 col-lg-4">
                    <RestaurantCard
                      id={item.id}
                      name={item.storeName}
                      banner={item.banner}
                      logo={item.avatar}
                      rating={item.rating}
                      ratingCount={item.ratingCount}
                    />
                  </div>
                ))
              : "No Data available"}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default FoodTrucks
