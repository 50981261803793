import axios from "axios"
import Cookies from "js-cookie"
import { errorHandler } from "../helper/errorhandler"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../utils/constants"

//axios.defaults.baseURL = "http://localhost:4000"
axios.defaults.baseURL = "https://backend.mealsbus.com"

const getJwt = () => {
  if (
    Cookies.get(JWT_STORAGE_NAME) !== undefined &&
    localStorage.getItem(JWT_STORAGE_NAME)
  ) {
    return Cookies.get(JWT_STORAGE_NAME)
    // return localStorage.getItem(JWT_STORAGE_NAME)
  } else {
    Cookies.remove(JWT_STORAGE_NAME)
    localStorage.removeItem(JWT_STORAGE_NAME)
    localStorage.removeItem(LOCAL_STORAGE_NAME)
  }
}

const setJwt = (token) => {
  Cookies.set(JWT_STORAGE_NAME, token, {
    expires: 2,
    path: "/",
    secure: false,
  })
  localStorage.setItem(JWT_STORAGE_NAME, token)
}

const setAuthHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
  setJwt(token)
}

if (getJwt()) {
  setAuthHeader(getJwt())
}

export default class Api {
  //login user
  static userLogin = async (data) => {
    try {
      const res = await axios.post(`/api/v1/user/login`, data)
      setAuthHeader(res.data.token)
      return res
    } catch (error) {
      localStorage.removeItem(JWT_STORAGE_NAME)
      localStorage.removeItem("mapsifyUser")
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //register user
  static registerUser = async (data) => {
    try {
      const res = await axios.post(`/api/v1/user/register`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //verify user
  static verifyUser = async (data) => {
    try {
      const res = await axios.put(`/api/v1/user/verify`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //forget password
  static forgetPassword = async (data) => {
    try {
      const res = await axios.post(`/api/v1/user/password/forget`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //reset password
  static resetPassword = async (data) => {
    try {
      const res = await axios.put(`/api/v1/user/password/reset`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get me profile
  static getMeProfile = async () => {
    try {
      const res = await axios.get(`/api/v1/user/me`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //update profile
  static updateProfile = async (data) => {
    try {
      const res = await axios.put(`/api/v1/user`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //update password
  static updatePassword = async (data) => {
    try {
      const res = await axios.put(`/api/v1/user/password/change`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get stores
  static getStores = async () => {
    try {
      const res = await axios.get(`/api/v1/restaurant/all`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get stores by type
  static getStoresByType = async (type) => {
    try {
      const res = await axios.get(`/api/v1/restaurant/all?type=${type}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get stores by Name
  static getStoresByName = async (name) => {
    try {
      const res = await axios.get(`/api/v1/restaurant/name/${name}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get all products
  static getAllProducts = async () => {
    try {
      const res = await axios.get(`/api/v1/products/all`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get stores by ID
  static getStoresByID = async (id) => {
    try {
      const res = await axios.get(`/api/v1/restaurant/id/${id}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get Product by Id
  static getProductById = async (id) => {
    try {
      const res = await axios.get(`/api/v1/restaurant/product/${id}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // setup stripe checkout
  static setupStripeCheckout = async (data) => {
    try {
      const res = await axios.post(`/api/v1/payment`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // create order
  static createOrder = async (data) => {
    try {
      const res = await axios.post(`/api/v1/order`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // get orders
  static getOrders = async (id) => {
    try {
      const res = await axios.get(`/api/v1/order/uid/${id}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // getOrderDetail
  static getOrderDetail = async (id) => {
    try {
      const res = await axios.get(`/api/v1/order/oid/${id}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // postNotification
  static postNotification = async (data) => {
    try {
      const res = await axios.post(`/api/v1/notification/add`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // getNotification
  static getNotifications = async (id) => {
    try {
      const res = await axios.get(`/api/v1/notification/uid/${id}/user`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // updateNotification
  static updateNotification = async (id) => {
    try {
      const res = await axios.put(`/api/v1/notification/update/seen/${id}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // post Review
  static postReview = async (data) => {
    try {
      const res = await axios.post(`/api/v1/review`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // update Status
  static updateOrder = async (data) => {
    try {
      const res = await axios.put(`/api/v1/order`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // user Reports
  static getReports = async (uid, year) => {
    try {
      const res = await axios.get(`/api/v1/user/report/${uid}/${year}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }
}
