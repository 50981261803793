import React from "react"
import Panel from "../../layout/Panel/Panel"
import AccountSettings from "../../components/dashboard/account/account"

const Account = () => {
  return (
    <Panel>
      <AccountSettings />
    </Panel>
  )
}

export default Account
