import "./Panel.css"
import Header from "../../components/dashboard/header/Header"
import Sidebar from "../../components/dashboard/sidebar/Sidebar"
import { useSelector } from "react-redux"
import { useEffect } from "react"

const Panel = ({ children }) => {
  const toggleSidebar = useSelector((state) => state.sidebar)

  useEffect(() => {
    const body = document.querySelector("body")
    body.style.overflow = "hidden"
    return () => {
      body.style.overflow = "scroll"
    }
  }, [])

  return (
    <section
      className="panel"
      id="panel"
    >
      <div className="panel_cont">
        <Sidebar />
        <div
          className={`dashboard ${
            toggleSidebar ? "panel_move" : "panel_no_move"
          }`}
        >
          <Header />
          <div className="dashboard_cont">{children}</div>
        </div>
      </div>
    </section>
  )
}

export default Panel
