import React from "react"
import AboutSection from "../../components/website/about/about"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"

function About() {
  return (
    <>
      <Header />
      <AboutSection />
      <Footer />
    </>
  )
}

export default About
