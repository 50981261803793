import { useCallback, useLayoutEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import {
  activeCategory,
  activeProducts,
  addData,
} from "../../store/slices/storeSlice"
import Footer from "../../components/website/footer/footer"
import Header from "../../components/website/header/header"
import Banner from "../../components/website/restaurant/banner/banner"
import Products from "../../components/website/restaurant/products/products"
import Sidebar from "../../components/website/restaurant/sidebar/sidebar"
import Loader from "../../components/dashboard/loader/loader"
import Api from "../../api/api"

const Restaurant = () => {
  const { name } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    store,
    activeCategory: activeCat,
    activeProducts: activeProd,
  } = useSelector((state) => state.store)
  const [isPageLoading, setIsPageLoading] = useState(true)

  const getData = useCallback(async () => {
    const res = await Api.getStoresByName(name)
    if (res.status === 200) {
      const data = res.data.data
      dispatch(addData(data))
      dispatch(activeCategory(data.categories[0]))
      dispatch(
        activeProducts(
          data.products.filter(
            (product) => product.cid === data.categories[0].id,
          ),
        ),
      )
    } else if (res.status === 404) {
      history.push("/")
    }
    setIsPageLoading(false)
  }, [])

  useLayoutEffect(() => {
    getData()
  }, [name])

  return (
    <>
      {isPageLoading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <Banner />
          <div
            className="container"
            style={{ padding: "30px 0" }}
          >
            <div
              className="row m-0 w-100"
              style={{ rowGap: 16 }}
            >
              <div className="col-12 col-md-4 col-lg-3">
                <Sidebar
                  activeCategory={activeCat}
                  categories={store.categories}
                />
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <Products
                  activeProducts={activeProd}
                  activeCategory={activeCat}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default Restaurant
