import Panel from "../../layout/Panel/Panel"
import OrderDescSection from "../../components/dashboard/order_description/od"

function OrderDescription() {
  return (
    <Panel>
      <OrderDescSection />
    </Panel>
  )
}

export default OrderDescription
