import React from "react";
import ResetSection from "../../components/website/reset/reset"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"

function Reset() {
    return(
        <>
            <Header />
            <ResetSection/>
            <Footer />
        </>
    )
}

export default Reset