import "./checkout.css"
import React, { useEffect, useLayoutEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import StripeCheckout from "react-stripe-checkout"
import { Container, Row, Col } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Loader from "../loader/loader"
import Api from "../../../api/api"
import { getCartTotal } from "../../../store/slices/cartSlice"
import { CLIENT_DETAIL, SELLER_LINK } from "../../../utils/constants"

function CheckoutSection() {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.user)
  const { cart, totalPrice: subTotal, rid } = useSelector((state) => state.cart)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [clientData, setClientData] = useState(
    JSON.parse(localStorage.getItem(CLIENT_DETAIL)) || {
      name: "",
      email: "",
      phone: "",
      address: "",
      zipcode: "",
      mapLink: "",
      deliveryType: "",
      isOrderForSomeoneElse: false,
    },
  )

  const updateMapLink = (newMapLink) => {
    setClientData((prev) => ({ ...prev, mapLink: newMapLink }));
    saveClientData();
  };

  const inputHandle = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setClientData((prev) => ({ ...prev, [name]: checked }));
    } else {
      setClientData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // const inputHandle = (e) => {
  //   const { name, value } = e.target
  //   setClientData((prev) => ({ ...prev, [name]: value }))
  // }

  const saveClientData = () => {
    localStorage.setItem(CLIENT_DETAIL, JSON.stringify(clientData))
  }

  const getStore = useCallback(async () => {
    const res = await Api.getStoresByID(rid)
    if (res.status === 200) {
      setData(res.data.data)
    }
  }, [data])

  const totalPrice = () => {
    const taxes =
      data?.deliveryCommission +
      data?.marketingCommission +
      data?.processingFee +
      data?.otherCharges +
      data?.salesTax

    const totalAmount =
      (taxes * parseFloat(subTotal)) / 100 + parseFloat(subTotal)
    return totalAmount.toFixed(2)
  }

  useLayoutEffect(() => {
    if (cart?.length <= 0) {
      return history.push("/cart")
    } else {
      getStore()
    }
  }, [])

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem(CLIENT_DETAIL))) {
      setClientData({
        name: user.name,
        email: user.email,
        phone: user.phone,
        address: "",
      })
    }
    dispatch(getCartTotal())
  }, [cart])

  const successPayment = async (paymentId) => {
    let orderData = {
      ...clientData,
      rid,
      price: totalPrice(),
      timeLeft: 45,
      paymentMethod: "card",
      // type: "pickup",
      products: cart,
      marketingCommission: data?.marketingCommission,
      deliveryCommission: data?.deliveryCommission,
      processingFee: data?.processingFee,
      otherCharges: data?.otherCharges,
      salesTax: data?.salesTax,
      paymentId,
      subTotal,
    }

    const res = await Api.createOrder(orderData)
    if (res.status === 201) {
      const res1 = await Api.postNotification({
        to_id: rid,
        to: "seller", //user, seller
        message: `${user.name} placed the new order #${res.data.data.id}`,
        link: `${SELLER_LINK}/order/${res.data.data.id}`,
      })
      if (res1.status === 201) {
        localStorage.removeItem("mealsbusCart")
        window.location.pathname = `/success/${res.data.data.id}`
      }
    }
  }

  const errorPayment = (data) => {
    alert("Payment Error")
    console.log(data)
    // setIsLoading(false)
  }

  const onToken = async (token) => {
    // setIsLoading(true)
    let data = {
      token: token,
      amount: totalPrice(),
    }

    let res = await Api.setupStripeCheckout(data)
    if (res.status === 200) {
      successPayment(res.data.data.customer.default_source)
    } else {
      errorPayment(data)
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <section
        className="section"
        style={{ background: "var(--bg_light)" }}
      >
        <Container fluid>
          <Row>
            <Col
              lg={8}
              md={12}
              xs={12}
            >
              <div className="cartItem btheme cWhite br5 chkDetails">
                <Row>
                  <Col
                    lg={11}
                    md={10}
                    xs={10}
                  >
                    <h4 className="subsubHead fw-bold">Delivery Details</h4>
                  </Col>
                  <Col
                    lg={1}
                    md={2}
                    xs={2}
                  >
                    <i
                      onClick={() => setModalShow(true)}
                      className="bi bi-pen pointer"
                    ></i>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col
                    lg={3}
                    md={4}
                    xs={4}
                  >
                    <h6 className="subsubsubHead fw-bold">Name:</h6>
                  </Col>
                  <Col
                    lg={9}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead ">{clientData?.name}</h6>
                  </Col>
                  <Col
                    lg={3}
                    md={4}
                    xs={4}
                  >
                    <h6 className="subsubsubHead fw-bold">Email:</h6>
                  </Col>
                  <Col
                    lg={9}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead ">{clientData?.email}</h6>
                  </Col>
                  <Col
                    lg={3}
                    md={4}
                    xs={4}
                  >
                    <h6 className="subsubsubHead fw-bold">Phone:</h6>
                  </Col>
                  <Col
                    lg={9}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead ">{clientData?.phone}</h6>
                  </Col>
                  <Col
                    lg={3}
                    md={4}
                    xs={4}
                  >
                    <h6 className="subsubsubHead fw-bold">Address:</h6>
                  </Col>
                  <Col
                    lg={9}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead ">{clientData?.address}</h6>
                  </Col>
                  <Col
                      lg={3}
                      md={4}
                      xs={4}
                  >
                    <h6 className="subsubsubHead fw-bold">Zipcode:</h6>
                  </Col>
                  <Col
                      lg={9}
                      md={8}
                      xs={8}
                  >
                    <h6 className="subsubsubHead ">{clientData?.zipcode}</h6>
                  </Col>
                  <Col
                      lg={3}
                      md={4}
                      xs={4}
                  >
                    <h6 className="subsubsubHead fw-bold">Live Location:</h6>
                  </Col>
                  <Col
                      lg={9}
                      md={8}
                      xs={8}
                  >
                    <h6 className="subsubsubHead ">{clientData?.mapLink}</h6>
                  </Col>
                  <Col
                      lg={3}
                      md={4}
                      xs={4}
                  >
                    <h6 className="subsubsubHead fw-bold">Delivery Type:</h6>
                  </Col>
                  <Col
                      lg={9}
                      md={8}
                      xs={8}
                  >
                    <h6 className="subsubsubHead ">{clientData?.deliveryType}</h6>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              lg={4}
              md={12}
              xs={12}
            >
              <div className="btheme p4 br5 cartsummary cWhite">
                <h4 className="subsubHead fw-bold">Verify and Payment</h4>
                <br />
                <Row>
                  <Col
                    lg={8}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead">Marketing Commission</h6>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <p>{data?.marketingCommission}%</p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={8}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead">Delivery Commission</h6>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <p>{data?.deliveryCommission}%</p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={8}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead">Processing Fee</h6>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <p>{data?.processingFee}%</p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={8}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead">Sales Tax</h6>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <p>{data?.salesTax}%</p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={8}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead">Other Charges</h6>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <p>{data?.otherCharges}%</p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={8}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead">Subtotal</h6>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <p>${subTotal}</p>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col
                    lg={8}
                    md={8}
                    xs={8}
                  >
                    <h6 className="subsubsubHead fw-bold">Total</h6>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <h6 className="subsubsubHead fw-bold">${totalPrice()}</h6>
                  </Col>
                </Row>
                <br />
                <p className="text-center">
                  <StripeCheckout
                    className="btn btn-light"
                    name="Mealsbus"
                    image="https://mealsbus.com/static/media/logo1.046bca6cab27b5c28756441b2a2be98d.svg"
                    amount={totalPrice() * 100} // cents
                    currency="USD" // the pop-in header title
                    token={onToken}
                    stripeKey="pk_test_51NCUQtCfHFJmCcGxZQ0cAeZT4jyNJCAExfCA6V7O8JDxKOtjbDSueWsKwH5ysjC3pp2p8BrNvT8tWZWUKsRGrHEq00thqIc2YY"
                  >
                    <button className="btn btn-light">Place Order</button>
                  </StripeCheckout>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <EditDetail
        show={modalShow}
        clientData={clientData}
        inputHandle={inputHandle}
        saveClientData={saveClientData}
        onHide={() => setModalShow(false)}
        updateMapLink={updateMapLink}
      />
    </>
  )
}

const EditDetail = ({show, onHide, clientData, inputHandle, saveClientData, updateMapLink}) => {

  let [link, setLink] = useState(null);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.")
    }
  }

  function showPosition(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    updateMapLink('https://www.google.com/maps?q='+latitude+','+longitude);
    setLink('https://www.google.com/maps?q='+latitude+','+longitude)
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Delivery Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col
              lg={2}
              md={1}
              xs={1}
            ></Col>
            <Col
              lg={8}
              md={10}
              xs={10}
            >
              <Form.Group className="mb-3">
                <Form.Label><b>Name</b></Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={clientData.name}
                  onChange={inputHandle}
                  placeholder="Enter Name"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><b>Email</b></Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={clientData.email}
                  onChange={inputHandle}
                  placeholder="Enter Email"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><b>Phone</b></Form.Label>
                <Form.Control
                  type="number"
                  name="phone"
                  value={clientData.phone}
                  onChange={inputHandle}
                  placeholder="Enter Phone"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><b>Zipcode</b></Form.Label>
                <Form.Control
                    type="number"
                    name="zipcode"
                    value={clientData.zipcode}
                    onChange={inputHandle}
                    placeholder="Enter Zipcode"
                    required
                />
              </Form.Group>


              <Form.Group className="mb-3">
                <Form.Label><b>Delivery Type</b></Form.Label>
                <Form.Select
                    name="deliveryType"  // Add the name attribute
                    value={clientData.deliveryType}  // Use the value prop to control the selected value
                    onChange={inputHandle}  // Add the onChange event to handle changes
                    aria-label="Select Delivery Type"
                >
                  <option value="">Open this select menu</option>
                  <option value="pickup">Pickup</option>
                  <option value="delivery">Delivery</option>
                </Form.Select>
              </Form.Group>


              {/*<Form.Group className="mb-3">*/}
              {/*  <Form.Label>Delivery Type</Form.Label>*/}
              {/*  <Form.Select aria-label="Default select example">*/}
              {/*    <option>Open this select menu</option>*/}
              {/*    <option value="pickup">Pickup</option>*/}
              {/*    <option value="2">Delivery</option>*/}
              {/*  </Form.Select>*/}
              {/*  <Form.Control*/}
              {/*      type="number"*/}
              {/*      name="zipcode"*/}
              {/*      value={clientData.deliveryType}*/}
              {/*      onChange={inputHandle}*/}
              {/*      placeholder="Enter Zipcode"*/}
              {/*      required*/}
              {/*  />*/}
              {/*</Form.Group>*/}


              <Form.Group className="mb-3">
                <Form.Label><b>Live Location</b></Form.Label>
                <br/>

                {
                  (clientData.isOrderForSomeoneElse == true)?
                      ""
                      :
                      <>
                        <Button style={{ background: "var(--primary)", border: 0 }} onClick={getLocation} className="btn-">Get Live Location</Button>
                        <br/>
                        <span>{link}</span>
                        <br/><br/>
                      </>
                }


                <Form.Check
                    type="checkbox"
                    label="Check if you are ordering the food for someone else"
                    name="isOrderForSomeoneElse"
                    checked={clientData.isOrderForSomeoneElse}
                    onChange={inputHandle}
                />
              </Form.Group>

              {clientData.isOrderForSomeoneElse && (
                  <Form.Group className="mb-3">
                    <Form.Label>Link for Someone Else</Form.Label>
                    <Form.Control
                        type="text"
                        name="mapLink"
                        value={clientData.mapLink}
                        onChange={inputHandle}
                        placeholder="Enter link for someone else"
                    />
                  </Form.Group>
              )}


              <Form.Group className="mb-3">
                <Form.Label><b>Address</b></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="address"
                  value={clientData.address}
                  onChange={inputHandle}
                  placeholder="Enter Delivery Address"
                  required
                />
              </Form.Group>
            </Col>
            <Col
              lg={2}
              md={1}
              xs={1}
            ></Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ background: "var(--primary)", border: 0 }}
          onClick={() => [saveClientData(), onHide()]}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CheckoutSection
