import { Link } from "react-router-dom"
import "./products.css"
import { useDispatch, useSelector } from "react-redux"
import { addItem } from "../../../../store/slices/cartSlice"
import { memo } from "react"

const Products = ({ activeCategory, activeProducts }) => {
  const { cart } = useSelector((state) => state.cart)

  const isItemExists = (id) => {
    const isFound = cart.find((item) => item.id === id)
    return isFound ? isFound.quantity : 0
  }

  return (
    <div className="products_wrapper">
      <h4 className="font-bold">
        {activeCategory?.name} ({activeProducts?.length})
      </h4>
      <div
        className="row mt-1"
        style={{ rowGap: 16 }}
      >
        {activeProducts && activeProducts.length > 0
          ? activeProducts.map((elem) => (
              <Product
                id={elem.id}
                image={elem.imgUrl}
                name={elem.name}
                price={elem.price}
                rating={elem.rating}
                elem={elem}
                isItemExists={isItemExists(elem.id)}
              />
            ))
          : "No Products Available"}
      </div>
    </div>
  )
}

const Product = ({ id, name, price, image, rating, elem, isItemExists }) => {
  const dispatch = useDispatch()
  const addToCart = (payload) => {
    dispatch(addItem(payload))
  }

  return (
    <div
      className="col-12 col-md-6 col-lg-4"
      key={id}
    >
      <div className="product_card">
        <Link
          to={`/product-detail/${id}`}
          className="product_image"
        >
          <img
            src={image}
            alt={name}
          />
        </Link>
        <div className="product_desc">
          <Link
            to={`/product-detail/${id}`}
            className="product_title"
          >
            <span>{name}</span>
          </Link>
          <div className="product_bottom">
            <span>${price}</span>

            <button onClick={() => addToCart(elem)}>
              <i className="bi bi-cart"></i> Add ({isItemExists})
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(Products)
