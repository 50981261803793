import React from "react";
import RegisterSection from "../../components/website/register/register"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"

function Register() {
    return(
        <>
            <Header />
            <RegisterSection/>
            <Footer />
        </>
    )
}

export default Register