import React from "react"
import LoginSection from "../../components/website/login/login"
import Header from "../../components/website/header/header"
import Footer from "../../components/website/footer/footer"

function Login() {
  return (
    <>
      <Header />
      <LoginSection />
      <Footer />
    </>
  )
}

export default Login
