import "./sidebar.css"
import { Offcanvas } from "react-bootstrap"
import { Link } from "react-router-dom"
import logo from "../../../assets/logo1.svg"
import useIsLogged from "../../../hook/isLogged"
import { SELLER_LINK } from "../../../utils/constants"

const Sidebar = ({ show, handleClose }) => {
  const isLogged = useIsLogged()
  return (
    <Offcanvas
      className="sidebar"
      show={show}
      onHide={handleClose}
    >
      <Offcanvas.Header className="sidebar_header">
        <img
          src={logo}
          alt="Mealsbus"
          decoding="async"
          loading="lazy"
          className="logo"
        />
        <span onClick={handleClose}>
          <i className="bi bi-x-lg"></i>
        </span>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="sidebar_links">
          <a href={`${SELLER_LINK}/register`}>Add Restaurant</a>
          <Link to="/home-kitchen">Home Kitchens</Link>
          <Link to="/food-truck">Food Trucks</Link>
          <Link to="/restaurant">Restaurants</Link>
          <Link to="/others">Others</Link>
        </div>
        <br />
        {!isLogged && (
          <>
            <Link
              to="/login"
              className="btn_primary"
            >
              Login
            </Link>
            &nbsp; &nbsp;
            <Link
              to="/register"
              className="btn_primary btn_primary2"
            >
              Signup
            </Link>
          </>
        )}
        <div className="socials_icons mt-4">
          <a
            className="coming_social_item"
            href="#"
          >
            <i className="bi bi-instagram"></i>
          </a>
          <a
            className="coming_social_item"
            href="#"
          >
            <i className="bi bi-youtube"></i>
          </a>
          <a
            className="coming_social_item"
            href="#"
          >
            <i className="bi bi-facebook"></i>
          </a>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default Sidebar
