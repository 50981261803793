import "./header.css"
import { useCallback, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Notifications from "../../../components/website/notifications/notifications"
import Sidebar from "../../../components/website/sidebar/sibebar"
import Usermenu from "../../../components/website/usermenu/usermenu"
import useIsLogged from "../../../hook/isLogged"
import logo from "../../../assets/logo1.svg"
import Api from "../../../api/api"

const Header = () => {
  const history = useHistory()
  const isLogged = useIsLogged()
  const user = useSelector((state) => state.user)
  const { cart } = useSelector((state) => state.cart)
  const [notifications, setNotifications] = useState([])
  const [show, setShow] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showNoti, setShowNoti] = useState(false)
  const showMenuRef = useRef()
  const showNotiRef = useRef()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const getNotifications = useCallback(async () => {
    const res = await Api.getNotifications(user?.id)
    if (res.status === 200) {
      setNotifications(res.data.data)
    }
  }, [setNotifications])

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <>
      <Sidebar
        show={show}
        handleClose={handleClose}
      />
      <div className="header">
        <div className="container">
          <div className="header_cont">
            <div className="header_left">
              <span
                className="hamburger"
                onClick={handleShow}
              >
                <i className="bi bi-list"></i>
              </span>
              &nbsp;
              <img
                src={logo}
                alt="Mealsbus"
                decoding="async"
                loading="lazy"
                className="logo"
                onClick={() => history.push("/")}
              />
            </div>
            <div className="header_right">
              {isLogged ? (
                <>
                  <div
                    className="avatar"
                    onClick={() => setShowMenu(true)}
                  >
                    <img
                      src={user?.avatar}
                      alt="avatar"
                      loading="lazy"
                      decoding="async"
                    />
                    {showMenu && (
                      <Usermenu
                        avatar={user?.avatar}
                        name={user?.name}
                        email={user?.email}
                        showUserMenuRef={showMenuRef}
                        setShowUserMenu={setShowMenu}
                      />
                    )}
                  </div>
                  &nbsp; &nbsp;&nbsp;
                  <div className="notifications position-relative">
                    <span onClick={() => setShowNoti(true)}>
                      <i className="bi bi-bell-fill"></i>
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {notifications.filter((item) => !item.seen).length}
                      </span>
                    </span>
                    {showNoti && (
                      <Notifications
                        notifications={notifications}
                        setShowNoti={setShowNoti}
                        showNotiRef={showNotiRef}
                      />
                    )}
                  </div>
                </>
              ) : (
                <Link
                  to="/login"
                  className="btn_primary"
                >
                  Login
                </Link>
              )}
              &nbsp; &nbsp;
              <Link
                to="/cart"
                className="position-relative"
              >
                <i className="bi bi-cart-fill"></i>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {cart.length}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
