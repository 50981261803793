import "./product_detail.css"
import React, { useCallback, useLayoutEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import parse from "html-react-parser"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Loader from "../../../components/dashboard/loader/loader"
import Api from "../../../api/api"
import { useDispatch, useSelector } from "react-redux"
import { addItemWithQuantity } from "../../../store/slices/cartSlice"

function ProductDetailSection() {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { cart } = useSelector((state) => state.cart)
  let [data, setData] = useState()
  let [isLoading, setIsLoading] = useState(true)
  let [counter, setCounter] = useState(0)

  const incrementCounter = () => {
    setCounter((prev) => prev + 1)
  }

  const decrementCounter = () => {
    setCounter((prev) => (prev <= 0 ? 0 : prev - 1))
  }

  const addItemToCart = () => {
    if (counter === 0) {
      return alert("Please select the quantity")
    }
    dispatch(addItemWithQuantity({ item: data, quantity: counter }))
    history.push("/cart")
  }

  const getData = useCallback(async () => {
    const res = await Api.getProductById(id)
    if (res.status === 200) {
      setData(res.data.data)
    } else if (res.status === 404) {
      history.push("/")
    }
    setIsLoading(false)
  }, [])

  useLayoutEffect(() => {
    getData()
    const isFound = cart.find((item) => item.id === parseInt(id))
    setCounter(isFound ? isFound.quantity : 0)
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section
          className="section"
          style={{ background: "var(--bg_light)" }}
        >
          <Container>
            <Row>
              <Col
                lg={5}
                md={12}
                xs={12}
              >
                <p
                  className="text-center"
                  style={{ position: "sticky", top: 10 }}
                >
                  <img
                    src={data?.imgUrl}
                    alt={data?.name}
                    style={{ width: "100%", objectFit: "cover" }}
                  />
                </p>
              </Col>
              <Col
                lg={7}
                md={12}
                xs={12}
              >
                <div className="detailsPane2">
                  <p style={{ fontSize: 13, fontWeight: 600 }}>
                    {data?.restaurant?.storeName}
                  </p>
                  <h2 className="subHead ctheme">{data?.name}</h2>
                  <h4 className="subHead fw-bold">${data.price}</h4>
                  <Row>
                    <Col
                      lg={3}
                      md={3}
                      xs={4}
                    >
                      <InputGroup
                        size="sm"
                        className="mb-1"
                      >
                        <InputGroup.Text
                          onClick={() => decrementCounter()}
                          className="pointer"
                        >
                          <i className="bi bi-dash-lg"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className="text-center"
                          value={counter}
                          disabled
                          aria-label="Amount (to the nearest dollar)"
                        />
                        <InputGroup.Text
                          onClick={() => incrementCounter()}
                          className="pointer"
                        >
                          <i className="bi bi-plus-lg"></i>
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                  <p className="text-center">
                    <button
                      className="btn_primary"
                      onClick={() => addItemToCart()}
                    >
                      Add to Cart ({counter})
                    </button>
                  </p>
                  <br />
                  {parse(data?.desc)}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

export default ProductDetailSection
